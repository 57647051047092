@import url('https://fonts.googleapis.com/css?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css?family=Barlow+Semi+Condensed&display=swap');

html {
  background: #212326;
  font-size: 62.5%;
}

html * {
  font-family: 'Bebas Neue', sans-serif
}

.App {
  /* background: #212326; */
  text-align: center;
  position: relative;
  min-height: 100vh;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 5rem;
}

.header--logo {
  max-width: 40rem;
}

.card-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.card-button {
  position: absolute;
  bottom: 8rem;
  left: 50%;
  transform: translateX(-50%);
  display: inline-block;
  font-size: 3rem;
  background: #000;
  color: #fff;
  padding: 2rem;
  border-radius: .2rem;
  cursor: pointer;
}
