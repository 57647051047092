.card {
    height: 50rem;
    width: 35rem;
    background: #fff;
    border-radius: 1rem;
    padding: 2rem;
    box-sizing: border-box;
}

.card * {
    cursor: default;
}

.card .title {
    text-transform: uppercase;
    /* font-size: 10rem; */
    margin: 0;
    line-height: 1;
}

.card.question {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.card .title span {
    clear: both;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    line-height: 1;
    
}

.card .title .pod {
    font-size: 17rem;
    text-shadow:
		-4px -4px 0 #000,
		4px -4px 0 #000,
		-4px 4px 0 #000,
        4px 4px 0 #000;
    color: #fff;
    letter-spacing: 10px;
    transform: skewX(-5deg);

}

.card .title .decks {
    font-size: 12rem;
    color: #fd6d07;
    text-shadow:
		-4px -4px 0 #000,
		4px -4px 0 #000,
		-4px 4px 0 #000,
        4px 4px 0 #000;
    letter-spacing: 10px;
    transform: scale(0.85);
    margin-top: -3rem;
}

.card .card-type {
    font-size: 4rem;
    font-weight: lighter;
    margin: 0;
    text-transform: capitalize;
    font-family: 'Barlow Semi Condensed', sans-serif;
}

.question-text {
    font-size: 4rem;
    color: #000;
}